import {
  ClearOutlined,
  DownOutlined,
  InfoCircleOutlined,
  UpOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Empty,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Spin,
  Tooltip,
  
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ImInsertTemplate } from "react-icons/im";
import { LuPencil } from "react-icons/lu";
import MainContext from "../../../../contexts/MainContext";
import TemplatesContext from "../../../../contexts/TemplatesContext";
import ToolsContext from "../../../../contexts/ToolsContext";
import TeamsContext from "../../../../contexts/TeamsContext"; // Add this line

import languagesOptions from "../../../../helpers/languagesOptions";
import filterOption from "../../../../helpers/selectFilterOption";
import ConfirmToolModal from "../ConfirmToolModal/ConfirmToolModal";
import CreateTemplateModal from "../CreateTemplateModal/CreateTemplateModal";
import ToolBase from "../ToolBase/ToolBase";
import TransitionToolWrapper from "../TransitionToolWrapper/TransitionToolWrapper";
import FilesUploader from "./components/FilesUploader/FilesUploader";
import draftingDisabledTooltips from "./helpers/draftingDisabledTooltips";
import { DraftingTypes } from "./helpers/draftingTypes.enum";
import descriptions from "./helpers/draftingTypesDescriptions";
import styleOptions from "./helpers/styleOptions";
import toneOptions from "./helpers/toneOptions";
import { DraftingForm } from "./interfaces/draftingForm.interface";
import { ToolTypes } from "../../../../enums/toolTypes.enum";
import getCookie from "../../../../helpers/getCookie";
import * as ToolsService from "../../services/tools.service";
import { useToast } from "../../../../contexts/ToastContext";

import styles from "./DocumentDraftingTool.module.css";

const DocumentDraftingTool: React.FC = () => {
  const mainContext = useContext(MainContext);
  const templatesContext = useContext(TemplatesContext);
  const toolsContext = useContext(ToolsContext);
  const teamsContext = useContext(TeamsContext); // Add this line

  const { showSuccessToast, toastResponse } = useToast();

  const csrfToken = getCookie("csrftoken") ?? "";

  const [draftingType, setDraftingType] = useState<DraftingTypes>(
    DraftingTypes.OneToOne
  );
  const [disclamerButtonHovered, setDisclamerButtonHovered] = useState(false);
  const [isDisclamerVisible, setIsDisclamerVisible] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const [template, setTemplate] = useState<
    | {
        value: number;
        label: string;
        id: number | string;
        drafting_type: string;
      }
    | undefined
  >(undefined);
  const [templates, setTemplates] = useState<
    Array<{
      value: number;
      label: string;
      id: number | string;
      drafting_type: string;
    }>
  >([]);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [isFetchingDraftingTemplates, setIsFetchingDraftingTemplates] =
    useState(false);

  const [formData, setFormData] = useState<DraftingForm>({
    additionalFileList: [],
    draftingTemplate: undefined,
    customPrompt: undefined,
    tone: "Neutral",
    style: { label: "Precise", value: 0.0 },
    language: "English",
    secondLanguage: "Spanish",
  });

  const [isBilingual, setIsBilingual] = useState(false);
  const [resultName, setResultName] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchDraftingTemplates = async () => {
      await mainContext?.fetchDraftingTemplateType();
      setIsFetchingDraftingTemplates(false);
    };
    setIsFetchingDraftingTemplates(true);
    fetchDraftingTemplates();
  }, [mainContext?.teamSelected]);

  const getTemplate = async (template: {
    value: number;
    label: string;
    id: number | string;
    drafting_type: string;
  }) => {
    setTemplate(template);
    if (!template) {
      setDraftingType(DraftingTypes.OneToOne);
      setFormData({
        additionalFileList: [],
        draftingTemplate: undefined,
        customPrompt: undefined,
        tone: "Neutral",
        style: { label: "Precise", value: 0.0 },
        language: "English",
        secondLanguage: "Spanish",
      });
      setIsBilingual(false);
      return;
    }
  };

  const createTemplate = async (templateName: string) => {
    console.log("CREATE TEMPLATE");
  };

  const updateTemplate = async (templateName: string) => {
    console.log("UPDATE TEMPLATE");
  };

  const deleteTemplate = async (data: { value: number; label: string }) => {
    console.log("DELETE TEMPLATE");
    setTemplates((templates) =>
      templates.filter((template) => template.value !== data.value)
    );
    setTemplate(undefined);
  };

  const renderResultNameField = () => (
    <Form.Item
      required
      label="Result name"
      className={styles.result_name_field}
    >
      <Input
        allowClear
        placeholder="Ex.: Declaration of Independence"
        value={resultName}
        onChange={(event) => setResultName(event.target.value)}
      />
    </Form.Item>
  );

  const subTitles = {
    [DraftingTypes.OneToOne]: "Proceed by generate documents from these files:",
    [DraftingTypes.MultipleToOne]: (
      <>
        {renderResultNameField()}
        <p>Proceed by generate documents from these files:</p>
      </>
    ),
    [DraftingTypes.TemplateBased]: (
      <>
        {renderResultNameField()}
        <p>Proceed by generate documents from this prompt:</p>
      </>
    ),
    [DraftingTypes.PromptBased]: (
      <>
        {renderResultNameField()}
        <p>Proceed by generate documents from this prompt:</p>
      </>
    ),
  };

  const confirmationLeftParts = {
    [DraftingTypes.OneToOne]: {
      header: "Files",
      body: [
        ...toolsContext?.selectedFiles.map((file) => file.name)!,
        ...formData.additionalFileList.map((file) => file.name),
      ],
    },
    [DraftingTypes.MultipleToOne]: {
      header: "Files",
      body: [...toolsContext?.selectedFiles.map((file) => file.name)!],
    },
    [DraftingTypes.TemplateBased]: {
      header: "Custom Prompt",
      body: [formData.customPrompt!],
    },
    [DraftingTypes.PromptBased]: {
      header: "Custom Prompt",
      body: [formData.customPrompt!],
    },
  };

  const confirmationRightParts = {
    [DraftingTypes.OneToOne]: {
      header: "Template Name",
      body: [formData.draftingTemplate?.label!],
    },
    [DraftingTypes.MultipleToOne]: {
      header: "Template Name",
      body: [formData.draftingTemplate?.label!],
    },
    [DraftingTypes.TemplateBased]: {
      header: "Template Name",
      body: [formData.draftingTemplate?.label!],
    },
    [DraftingTypes.PromptBased]: {
      header: "Configuration",
      body: [formData.tone, formData.style.label],
    },
  };

  const isTemplatingDisabled = {
    [DraftingTypes.OneToOne]: !formData.draftingTemplate || !formData.language,
    [DraftingTypes.MultipleToOne]:
      !formData.draftingTemplate || !formData.language,
    [DraftingTypes.TemplateBased]:
      !formData.draftingTemplate ||
      !formData.customPrompt ||
      !formData.language,
    [DraftingTypes.PromptBased]:
      !formData.customPrompt ||
      !formData.tone ||
      !formData.style ||
      !formData.language,
  };

  const isDraftingDisabled = {
    [DraftingTypes.OneToOne]:
      toolsContext?.selectedFiles.length === 0 ||
      isTemplatingDisabled[DraftingTypes.OneToOne],
    [DraftingTypes.MultipleToOne]:
      toolsContext?.selectedFiles.length === 0 ||
      isTemplatingDisabled[DraftingTypes.MultipleToOne],
    [DraftingTypes.TemplateBased]:
      isTemplatingDisabled[DraftingTypes.TemplateBased],
    [DraftingTypes.PromptBased]:
      isTemplatingDisabled[DraftingTypes.PromptBased],
  };

  const submitRequests = {
    [DraftingTypes.OneToOne]: {
      tool_action: () =>
        ToolsService.draftingDocumentsOneToOne(
          csrfToken,
          toolsContext?.selectedFiles.map((file) => file.id)!,
          formData.additionalFileList,
          formData.customPrompt!,
          formData.draftingTemplate?.value!,
          isBilingual
            ? [formData.language, formData.secondLanguage]
            : [formData.language]
        ),
    },
    [DraftingTypes.MultipleToOne]: {
      tool_action: () =>
        ToolsService.draftingDocumentsManyToOne(
          csrfToken,
          toolsContext?.selectedFiles.map((file) => file.id)!,
          formData.customPrompt ?? "",
          formData.draftingTemplate?.value!,
          resultName!,
          isBilingual
            ? [formData.language, formData.secondLanguage]
            : [formData.language]
        ),
    },
    [DraftingTypes.TemplateBased]: {
      tool_action: () =>
        ToolsService.draftingDocumentsTemplateBased(
          csrfToken,
          mainContext?.projectSelected!,
          formData.customPrompt!,
          formData.draftingTemplate?.value!,
          resultName!,
          formData.language
        ),
    },
    [DraftingTypes.PromptBased]: {
      tool_action: () =>
        ToolsService.draftingDocumentsCustomPromptBased(
          csrfToken,
          mainContext?.projectSelected!,
          formData.customPrompt!,
          formData.tone,
          formData.style.value,
          resultName!,
          formData.language
        ),
    },
  };

  return (
    <>
      <TransitionToolWrapper>
        <ToolBase
          icon={<LuPencil />}
          title="Document drafting"
          template={template}
          getTemplate={getTemplate}
          fetchingTemplates={fetchingTemplates}
          templates={templates}
          deleteTemplate={deleteTemplate}
          isDevelopmentMode={true}
        >
          <Form className={styles.form} layout="vertical">
            <Form.Item required={true} label="Drafting type">
              <Radio.Group
                disabled={!!template}
                buttonStyle="solid"
                value={draftingType}
                onChange={(event) => {
                  setDraftingType(event.target.value);
                  setFormData({
                    additionalFileList: [],
                    draftingTemplate: undefined,
                    customPrompt: undefined,
                    tone: "Neutral",
                    style: { label: "Precise", value: 0.0 },
                    language: "English",
                    secondLanguage: "Spanish",
                  });
                  setIsBilingual(false);
                }}
              >
                <Radio.Button value={DraftingTypes.OneToOne}>
                  One to One
                </Radio.Button>
                <Radio.Button value={DraftingTypes.MultipleToOne}>
                  Multiple to One
                </Radio.Button>
                <Radio.Button value={DraftingTypes.TemplateBased}>
                  Template based
                </Radio.Button>
                <Radio.Button value={DraftingTypes.PromptBased}>
                  Prompt based
                </Radio.Button>
              </Radio.Group>
              <Button
                className={styles.disclamer_button}
                type="dashed"
                onMouseEnter={() => setDisclamerButtonHovered(true)}
                onMouseLeave={() => setDisclamerButtonHovered(false)}
                onClick={() => setIsDisclamerVisible((prevState) => !prevState)}
                icon={
                  isDisclamerVisible ? (
                    <UpOutlined />
                  ) : disclamerButtonHovered ? (
                    <DownOutlined className={styles.disclamer_icon} />
                  ) : (
                    <InfoCircleOutlined className={styles.disclamer_icon} />
                  )
                }
              >
                Disclamer
              </Button>
            </Form.Item>

            <div
              className={`${styles.description} ${
                !isDisclamerVisible ? styles.hidden : ""
              }`}
            >
              {descriptions[draftingType]}
            </div>
            {isDisclamerVisible && <div className={styles.divider}></div>}

            {draftingType === DraftingTypes.OneToOne && (
              <Form.Item label="Additional files">
                <FilesUploader formData={formData} setFormData={setFormData} />
              </Form.Item>
            )}

            {[
              DraftingTypes.OneToOne,
              DraftingTypes.MultipleToOne,
              DraftingTypes.TemplateBased,
            ].includes(draftingType) && (
              <Form.Item
                required
                label={
                  <>
                    <span className={styles.drafting_template_label}>
                      Drafting template
                    </span>
                    <Tooltip
                      overlayInnerStyle={{ width: "400px" }}
                      title={
                        <>
                          <p className={styles.drafting_template_hint_title}>
                            Where can I get this?
                          </p>
                          <p>
                            Firstly, you need to create a Drafting template in
                            Tools. You can do this by{" "}
                            <a href={`/teams?menu=tools&tool=DocumentDrafting&teamId=${mainContext?.teamSelected?.id}`}>
                              clicking here
                            </a>
                            . After creating a template, you can select it here.
                          </p>
                        </>
                      }
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </>
                }
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  placeholder="Select drafting template"
                  loading={isFetchingDraftingTemplates}
                  notFoundContent={
                    isFetchingDraftingTemplates ? (
                      <Spin size="small" />
                    ) : (
                      <Empty
                        description="No templates"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    )
                  }
                  onChange={(value: string, option: any) =>
                    setFormData({
                      ...formData,
                      draftingTemplate: option ? option : undefined,
                    })
                  }
                  value={formData.draftingTemplate?.label}
                  options={mainContext?.draftingTemplateTypes.map(
                    (draftingTemplate) => ({
                      value: draftingTemplate.id,
                      label: draftingTemplate.name,
                      description: draftingTemplate.description,
                    })
                  )}
                  optionRender={(option) => (
                    <Space>
                      <Tooltip
                        overlayClassName={styles.description_tooltip}
                        title={option.data.description}
                      >
                        <span>{option.label}</span>
                      </Tooltip>
                    </Space>
                  )}
                />
              </Form.Item>
            )}

            <Form.Item
              required={[
                DraftingTypes.TemplateBased,
                DraftingTypes.PromptBased,
              ].includes(draftingType)}
              label="Custom prompt"
            >
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                maxLength={1000}
                allowClear
                placeholder="Type custom prompt"
                value={formData.customPrompt}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    customPrompt: event.target.value,
                  })
                }
              />
            </Form.Item>

            {draftingType === DraftingTypes.PromptBased && (
              <>
                <Form.Item label="Tone">
                  <Select
                    showSearch
                    filterOption={filterOption}
                    placeholder="Select the tone"
                    onChange={(value: string, option: any) =>
                      setFormData({
                        ...formData,
                        tone: option.label,
                      })
                    }
                    value={formData.tone}
                    options={toneOptions}
                    optionRender={(option) => (
                      <Space>
                        <Tooltip
                          overlayClassName={styles.description_tooltip}
                          title={option.data.description}
                        >
                          <span>{option.label}</span>
                        </Tooltip>
                      </Space>
                    )}
                  />
                </Form.Item>
                <Form.Item label="Style (temperature)">
                  <Select
                    showSearch
                    filterOption={filterOption}
                    placeholder="Select the style"
                    onChange={(value: string, option: any) =>
                      setFormData({
                        ...formData,
                        style: { label: option.label, value: option.value },
                      })
                    }
                    value={formData.style.label}
                    options={styleOptions}
                  />
                </Form.Item>
              </>
            )}

            <Form.Item
              className={styles.language_input}
              label="Language of the output"
            >
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select the language of the output"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    language: option.label,
                  })
                }
                value={formData.language}
                options={languagesOptions}
              />
            </Form.Item>

            {[DraftingTypes.OneToOne, DraftingTypes.MultipleToOne].includes(
              draftingType
            ) && (
              <>
                <div className={styles.divider}></div>

                <Checkbox
                  className={styles.bilingual_checkbox}
                  checked={isBilingual}
                  onChange={() => setIsBilingual((prev) => !prev)}
                >
                  Bilingual document
                </Checkbox>

                <Form.Item
                  className={styles.language_input}
                  label="Second language of the output"
                >
                  <Select
                    disabled={!isBilingual}
                    showSearch
                    filterOption={filterOption}
                    placeholder="Select the second language of the output"
                    onChange={(value: string, option: any) =>
                      setFormData({
                        ...formData,
                        secondLanguage: option.label,
                      })
                    }
                    value={formData.secondLanguage}
                    options={languagesOptions}
                  />
                </Form.Item>
              </>
            )}

            <div className={styles.tool_footer}>
              <Button
                type="primary"
                ghost
                size="middle"
                icon={<ClearOutlined className={styles.button_icon} />}
                disabled={
                  (formData.additionalFileList.length === 0 &&
                    !formData.draftingTemplate &&
                    !formData.customPrompt &&
                    !isBilingual) ||
                  !!template
                }
                onClick={() => {
                  setFormData({
                    additionalFileList: [],
                    draftingTemplate: undefined,
                    customPrompt: undefined,
                    tone: "Neutral",
                    style: { label: "Precise", value: 0.0 },
                    language: "English",
                    secondLanguage: "Spanish",
                  });
                  setIsBilingual(false);
                }}
              >
                Clear All Fields
              </Button>

              <Tooltip
                placement="bottom"
                title={
                  isDraftingDisabled[draftingType]
                    ? draftingDisabledTooltips[draftingType]
                    : ""
                }
              >
                <Button
                  type="primary"
                  size="middle"
                  icon={<LuPencil className={styles.button_icon} />}
                  disabled={isDraftingDisabled[draftingType]}
                  onClick={() => setShowSubmitModal(true)}
                  loading={showSubmitModal}
                >
                  Draft Documents
                </Button>
              </Tooltip>

              <Tooltip title="In Development">
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  icon={<ImInsertTemplate className={styles.button_icon} />}
                  // disabled={isTemplatingDisabled[draftingType]}
                  disabled
                  onClick={() => setShowCreateTemplateModal(true)}
                >
                  {template ? "Update Template" : "Create Template"}
                </Button>
              </Tooltip>
            </div>
          </Form>
        </ToolBase>
      </TransitionToolWrapper>

      <ConfirmToolModal
        isOpen={showSubmitModal}
        title="Draft Documents"
        subTitle={subTitles[draftingType]}
        icon={<LuPencil className={styles.modal_icon} />}
        leftPart={confirmationLeftParts[draftingType]}
        rightPart={confirmationRightParts[draftingType]}
        onClose={() => {
          setShowSubmitModal(false);
          setResultName(undefined);
        }}
        action={async () => {
          toolsContext?.setSelectedTools((prev) =>
            prev.filter((tool) => tool !== ToolTypes.DocumentDrafting)
          );

          const request = submitRequests[draftingType].tool_action;
          const responseData = await request();

          toastResponse<{ file_name: string }[] | { output_name: string }>(
            responseData
          ).then((result) => {
            showSuccessToast(
              "Success",
              <p>
                Document drafting:{" "}
                {Array.isArray(result) ? (
                  result.map(({ file_name }, index) => (
                    <span key={index} className={styles.bold_italic_text}>
                      {file_name} {index !== result.length - 1 && ","}{" "}
                    </span>
                  ))
                ) : (
                  <span className={styles.bold_italic_text}>
                    {result.output_name}
                  </span>
                )}{" "}
                was completed successfully!
              </p>
            );
          });

          await mainContext?.fetchNotifications();
        }}
        isSubmitDisabled={
          !resultName && draftingType !== DraftingTypes.OneToOne
        }
      />

      <CreateTemplateModal
        isOpen={showCreateTemplateModal}
        title={
          template
            ? "Update Document Drafting Template"
            : "New Document Drafting Template"
        }
        subTitle={
          template
            ? "Type a new name for this template"
            : "Please type a name for new document drafting template."
        }
        onClose={() => setShowCreateTemplateModal(false)}
        action={template ? updateTemplate : createTemplate}
        predefinedValue={template ? template.label : ""}
      />
    </>
  );
};

export default DocumentDraftingTool;
