import { useContext, useState, useEffect } from "react";
import MainContext from "../../../../contexts/MainContext";
import TemplatesContext from "../../../../contexts/TemplatesContext";
import ToolsContext from "../../../../contexts/ToolsContext";
import TransitionToolWrapper from "../TransitionToolWrapper/TransitionToolWrapper";
import ToolBase from "../ToolBase/ToolBase";
import ConfirmToolModal from "../ConfirmToolModal/ConfirmToolModal";
import CreateTemplateModal from "../CreateTemplateModal/CreateTemplateModal";
import { CgDanger } from "react-icons/cg";
import { ClearOutlined } from "@ant-design/icons";
import { ImInsertTemplate } from "react-icons/im";
import { Select, Space, Form, Button as ButtonAntd, Tooltip } from "antd";
import filterOption from "../../../../helpers/selectFilterOption";
import languagesOptions from "../../../../helpers/languagesOptions";
import { ToolTypes } from "../../../../enums/toolTypes.enum";
import getCookie from "../../../../helpers/getCookie";
import * as ToolsService from "../../services/tools.service";
import { useToast } from "../../../../contexts/ToastContext";

import styles from "./RisksIdentifierTool.module.css";

interface RisksForm {
  documentType: { id: number | string; name: string } | undefined;
  risks: { label: string; value: number | string; description: string }[];
  language: string;
}

const RisksIndentifierTool: React.FC = () => {
  const mainContext = useContext(MainContext);
  const templatesContext = useContext(TemplatesContext);
  const toolsContext = useContext(ToolsContext);

  const { showSuccessToast, toastResponse } = useToast();

  const csrfToken = getCookie("csrftoken") ?? "";

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const [template, setTemplate] = useState<
    { value: number; label: string } | undefined
  >(undefined);
  const [templates, setTemplates] = useState<
    Array<{ value: number; label: string }>
  >([]);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);

  const [formData, setFormData] = useState<RisksForm>({
    documentType: undefined,
    risks: [],
    language: "English",
  });

  const getTemplate = async (template: { value: number; label: string }) => {
    setTemplate(template);
    if (!template) {
      setFormData({
        documentType: undefined,
        risks: [],
        language: "English",
      });
      return;
    }
    const riskTemplate = await templatesContext?.fetchRisk(template.value);
    if (!riskTemplate) {
      setFormData({
        documentType: undefined,
        risks: [],
        language: "English",
      });
    } else {
      setFormData({
        documentType: riskTemplate.contract_type,
        risks: riskTemplate.risks.map((risk) => ({
          value: risk.id,
          label: risk.name,
          description: risk.description,
        })),
        language: riskTemplate.language,
      });
    }
  };

  const deleteTemplate = async (data: { value: number; label: string }) => {
    await templatesContext?.deleteRisk(data.value);
    setTemplates((templates) =>
      templates.filter((template) => template.value !== data.value)
    );
    setTemplate(undefined);
  };

  const createTemplate = async (templateName: string) => {
    const newTemplateId = await templatesContext?.createRisk({
      name: templateName,
      risks: formData.risks.map((risk) => risk.value),
      language: formData.language,
    });
    setFetchingTemplates(true);
    const riskTemplates = await templatesContext?.fetchAllRisks();
    const parsedTemplates = riskTemplates ?? [];

    setTemplate({ value: newTemplateId as number, label: templateName });
    setTemplates(
      parsedTemplates.map((template) => ({
        value: template.id as number,
        label: template.name,
      }))
    );
    setFetchingTemplates(false);
  };

  const updateTemplate = async (templateName: string) => {
    await templatesContext?.editRisk(template!.value, {
      name: templateName,
      risks: formData.risks.map((risk) => risk.value),
      language: formData.language,
    });
    setFetchingTemplates(true);
    const riskTemplates = await templatesContext?.fetchAllRisks();
    const parsedTemplates = riskTemplates ?? [];

    setTemplates(
      parsedTemplates.map((template) => ({
        value: template.id as number,
        label: template.name,
      }))
    );
    setTemplate((oldTemplate) => ({
      value: oldTemplate!.value,
      label: templateName,
    }));
    setFetchingTemplates(false);
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      const riskTemplates = await templatesContext?.fetchAllRisks();
      const parsedTemplates = riskTemplates ?? [];

      setTemplates(
        parsedTemplates.map((template) => ({
          value: template.id as number,
          label: template.name,
        }))
      );
      setFetchingTemplates(false);
    };

    setFetchingTemplates(true);
    fetchTemplates();
  }, []);

  return (
    <>
      <TransitionToolWrapper>
        <ToolBase
          icon={<CgDanger />}
          title="Risks identifier"
          getTemplate={getTemplate}
          template={template}
          templates={templates}
          fetchingTemplates={fetchingTemplates}
          deleteTemplate={deleteTemplate}
        >
          <Form className={styles.form} layout="vertical">
            <Form.Item required label="Document type">
              <Select
                allowClear
                showSearch
                filterOption={filterOption}
                placeholder="Select document type"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    documentType: option
                      ? { id: option.value, name: option.label }
                      : undefined,
                    risks: [],
                  })
                }
                value={formData.documentType?.name}
                options={mainContext?.riskContractTypes.map((contractType) => ({
                  value: contractType.id,
                  label: contractType.name,
                }))}
              />
            </Form.Item>

            <Form.Item required label="Risks to identify">
              <Select
                disabled={!formData.documentType}
                mode="multiple"
                size="middle"
                filterOption={filterOption}
                allowClear
                showSearch
                placeholder="Select risks"
                onChange={(value, option: any) =>
                  setFormData({
                    ...formData,
                    risks: option ? option : [],
                  })
                }
                value={formData.risks}
                options={mainContext?.riskContractTypes
                  .filter(
                    (contractType) =>
                      contractType.id === formData.documentType?.id
                  )[0]
                  ?.risk_types.map((risk) => ({
                    value: risk.id,
                    label: risk.name,
                    description: risk.description,
                  }))}
                optionRender={(option) => (
                  <Space>
                    <Tooltip
                      overlayClassName={styles.description_tooltip}
                      title={option.data.description}
                    >
                      <span>{option.label}</span>
                    </Tooltip>
                  </Space>
                )}
              />
            </Form.Item>

            <Form.Item
              className={styles.language_input}
              label="Language of the output"
            >
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select the language of the output"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    language: option.label,
                  })
                }
                value={formData.language}
                options={languagesOptions}
              />
            </Form.Item>

            <div className={styles.tool_footer}>
              <ButtonAntd
                type="primary"
                ghost
                size="middle"
                icon={<ClearOutlined className={styles.button_icon} />}
                disabled={
                  (!formData.documentType && formData.risks.length === 0) ||
                  !!template
                }
                onClick={() =>
                  setFormData({
                    documentType: undefined,
                    risks: [],
                    language: "English",
                  })
                }
              >
                Clear All Fields
              </ButtonAntd>

              <Tooltip
                placement="bottom"
                title={
                  toolsContext?.selectedFiles.length === 0 ||
                  formData.risks.length === 0 ||
                  !formData.language
                    ? "Please select the files you want to work with and the risks you want to extract."
                    : ""
                }
              >
                <ButtonAntd
                  type="primary"
                  size="middle"
                  icon={<CgDanger className={styles.button_icon} />}
                  disabled={
                    toolsContext?.selectedFiles.length === 0 ||
                    formData.risks.length === 0 ||
                    !formData.language
                  }
                  onClick={() => setShowSubmitModal(true)}
                  loading={showSubmitModal}
                >
                  Identify Risks
                </ButtonAntd>
              </Tooltip>

              <ButtonAntd
                type="primary"
                ghost
                size="middle"
                icon={<ImInsertTemplate className={styles.button_icon} />}
                disabled={
                  !formData.documentType ||
                  formData.risks.length === 0 ||
                  !formData.language
                }
                onClick={() => setShowCreateTemplateModal(true)}
              >
                {template ? "Update Template" : "Create Template"}
              </ButtonAntd>
            </div>
          </Form>
        </ToolBase>
      </TransitionToolWrapper>

      <ConfirmToolModal
        isOpen={showSubmitModal}
        title="Extract Risks"
        subTitle="Proceed by extracting risks from these files:"
        icon={<CgDanger className={styles.modal_icon} />}
        leftPart={{
          header: "Files",
          body: toolsContext?.selectedFiles.map((file) => file.name)!,
        }}
        rightPart={{
          header: "Risks",
          body: formData.risks.map((item) => item.label),
        }}
        onClose={() => setShowSubmitModal(false)}
        action={async () => {
          toolsContext?.setSelectedTools((prev) =>
            prev.filter((tool) => tool !== ToolTypes.RisksIdentifier)
          );

          const responseData = await ToolsService.identifyRisks(
            csrfToken,
            toolsContext?.selectedFiles.map((file) => file.id)!,
            formData.risks.map((risk) => ({
              risk: risk.label,
              task: risk.description,
              mitigation: "",
              situation: "",
              example: "",
            })),
            formData.language
          );

          toastResponse<{ file_name: string }[]>(responseData).then(
            (result) => {
              showSuccessToast(
                "Success",
                <p>
                  Risks extraction from files:{" "}
                  {result.map(({ file_name }, index) => (
                    <span key={index} className={styles.bold_italic_text}>
                      {file_name} {index !== result.length - 1 && ","}{" "}
                    </span>
                  ))}{" "}
                  was completed successfully!
                </p>
              );
            }
          );

          await mainContext?.fetchNotifications();
        }}
      />

      <CreateTemplateModal
        isOpen={showCreateTemplateModal}
        title={
          template
            ? "Update Risk Identifier Template"
            : "New Risk Indentifier Template"
        }
        subTitle={
          template
            ? "Type a new name for this template"
            : "Please type a name for new risk indentifier template."
        }
        onClose={() => setShowCreateTemplateModal(false)}
        action={template ? updateTemplate : createTemplate}
        predefinedValue={template ? template.label : ""}
      />
    </>
  );
};

export default RisksIndentifierTool;
