import { StreamingTextResponse } from "ai";
import React, {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useContext,
} from "react";
import Notification_ from "../data/Notification_";
import {
  ComparisonChecklist,
  ComparisonRegulation,
  DraftingTemplateType,
  MetadataContractType,
  ObligationContractType,
  PSComparisonFeature,
  RiskContractType,
  SummarizationContractType,
  SummarizationToneType,
} from "../data/PredefinedToolInputs";
import Project from "../data/Project";
import Team from "../data/Team";
import { UserValue } from "../data/User";
import getCookie from "../helpers/getCookie";
import { TextViewProps } from "../components/TextView";
import downloadFile from "../helpers/downloadFile";
import TeamsContext from "./TeamsContext";
import { set } from "lodash";
import { cp } from "fs";
import { useToast } from "./ToastContext";
import ToolsContext from "./ToolsContext";
interface MainContextType {
  notifications: Array<Notification_>;
  teams: Array<Team>;
  setTeams: React.Dispatch<React.SetStateAction<Array<Team>>>;
  teamSelected: Team | undefined;
  setTeamSelected: React.Dispatch<React.SetStateAction<Team | undefined>>;
  fetchTeams: () => Promise<void>;
  searchUsers: (search: string) => Promise<UserValue[]>;
  acceptTeamInvitation: (notificationId: string | number) => Promise<void>;
  declineTeamInvitation: (notificationId: string | number) => Promise<void>;

  projects: Array<Project>;
  projectSelected: Project | undefined;
  setProjectSelected: React.Dispatch<React.SetStateAction<Project | undefined>>;
  fetchProjects: () => Promise<void>;
  createProject: (
    name: string,
    description: string
  ) => Promise<{ name: string }>;
  editProject: (
    id: number | string,
    data: { name: string; description: string }
  ) => Promise<{ name: string }>;
  deleteProject: (id: number | string) => Promise<void>;

  menuItemSelected: "TOOLS" | "RESULTS" | "SEARCH";
  setMenuItemSelected: React.Dispatch<
    React.SetStateAction<"TOOLS" | "RESULTS" | "SEARCH">
  >;

  searchMenuItemsSelected: "search" | "chat";
  setSearchMenuItemsSelected: React.Dispatch<
    React.SetStateAction<"search" | "chat">
  >;
  searchProjectSelected: string | number;
  setSearchProjectSelected: React.Dispatch<
    React.SetStateAction<string | number>
  >;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;

  fetchNotifications: () => Promise<void>;
  updateNotificationsStatus: (
    ids: Array<string | number>,
    status: boolean
  ) => Promise<void>;
  readAllNotifications: () => Promise<void>;
  deleteNotification: (notificationId: string | number) => Promise<void>;
  deleteAllNotifications: () => Promise<void>;

  metadataContractTypes: Array<MetadataContractType>;
  obligationContractTypes: Array<ObligationContractType>;
  riskContractTypes: Array<RiskContractType>;
  comparisonRegulationTypes: Array<ComparisonRegulation>;
  comparisonChecklistTypes: Array<ComparisonChecklist>;
  draftingTemplateTypes: Array<DraftingTemplateType>;
  summarizationDocumentTypes: Array<SummarizationContractType>;
  summarizationTones: Array<SummarizationToneType>;

  fetchMetadataContractTypes: () => Promise<void>;
  deleteMetadataContractType: (id: string | number) => Promise<void>;
  createMetadataContractType: (data: { name: string }) => Promise<void>;
  editMetadataContractType: (
    id: string | number,
    data: { name: string }
  ) => Promise<void>;
  deleteMetadataType: (id: string | number) => Promise<void>;
  createMetadataType: (
    metadataContractTypeId: string | number,
    data: {
      name: string;
      description: string;
      type: string;
      output_format: string;
    }
  ) => Promise<void>;
  editMetadataType: (
    id: string | number,
    data: {
      name: string;
      description: string;
      type: string;
      output_format: string;
    }
  ) => Promise<void>;

  deleteObligationContractType: (id: string | number) => Promise<void>;
  createObligationContractType: (data: { name: string }) => Promise<void>;
  editObligationContractType: (
    id: string | number,
    data: { name: string }
  ) => Promise<void>;
  deleteObligationType: (id: string | number) => Promise<void>;
  createObligationType: (
    obligationContractTypeId: string | number,
    data: { name: string }
  ) => Promise<void>;
  editObligationType: (
    id: string | number,
    data: { name: string; description: string }
  ) => Promise<void>;

  deleteRiskContractType: (id: string | number) => Promise<void>;
  createRiskContractType: (data: { name: string }) => Promise<void>;
  editRiskContractType: (
    id: string | number,
    data: { name: string }
  ) => Promise<void>;
  deleteRiskType: (id: string | number) => Promise<void>;
  createRiskType: (
    riskContractTypeId: string | number,
    data: { name: string; description: string }
  ) => Promise<void>;
  editRiskType: (
    id: string | number,
    data: { name: string; description: string }
  ) => Promise<void>;

  deleteComparisonRegulationType: (id: string | number) => Promise<void>;
  createComparisonRegulationType: (data: { name: string }) => Promise<void>;
  editComparisonRegulationType: (
    id: string | number,
    data: { name: string }
  ) => Promise<void>;

  deleteComparisonChecklistType: (id: string | number) => Promise<void>;
  createComparisonChecklistType: (data: { name: string }) => Promise<void>;
  editComparisonChecklistType: (
    id: string | number,
    data: { name: string }
  ) => Promise<void>;

  fetchObligationContractTypes: () => Promise<void>;
  fetchRiskContractTypes: () => Promise<void>;
  fetchComparisonRegulations: () => Promise<void>;
  fetchComparisonChecklists: () => Promise<void>;
  fetchSummarizationContractTypes: () => Promise<void>;
  fetchSummarizationToneTypes: () => Promise<void>;
  fetchDraftingTemplateType: () => Promise<void>;
  deleteDraftingTemplateType: (id: string | number) => Promise<void>;
  editDraftingTemplateType: (
    id: string | number,
    data: { name: string; description: string }
  ) => Promise<void>;
  createDraftingTemplateType: (data: {
    name: string;
    description: string;
    file: any;
    baseline: string;
  }) => Promise<void>;

  // exportMetadataToCSV: (resultId: string | number) => Promise<void>;
  // batchExportMetadataToCSV: (resultIds: Array<string | number | bigint>) => Promise<void>;
  // exportObligationToCSV: (resultId: string | number) => Promise<void>;
  // batchExportObligationToCSV: (resultIds: Array<string | number | bigint>) => Promise<void>;
  // exportRiskToCSV: (resultId: string | number) => Promise<void>;
  // batchExportRiskToCSV: (resultIds: Array<string | number | bigint>) => Promise<void>;
  // exportComparisonToCSV: (resultId: string | number) => Promise<void>;
  // batchExportComparisonToCSV: (resultIds: Array<string | number | bigint>) => Promise<void>;
  handleChatRequest: (
    messages: Array<any>,
    project: string | number,
     cb: () => void
  ) => Promise<StreamingTextResponse>;
  handleSearchRequest: (
    query: string,
    project: string | number,
    page: number
  ) => Promise<Response>;
  searchResults: Array<any>;
  setSearchResults: React.Dispatch<React.SetStateAction<any>>;
  searchPage: number;
  setSearchPage: React.Dispatch<React.SetStateAction<number>>;
  chatMessages: Array<any>;
  setChatMessages: React.Dispatch<React.SetStateAction<any>>;
  searchPagesNum: number;
  setSearchPagesNum: React.Dispatch<React.SetStateAction<number>>;
  isTextViewVisible: boolean;
  setIsTextViewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  textViewContent: TextViewProps;
  setTextViewContent: React.Dispatch<React.SetStateAction<TextViewProps>>;
  get_doc_text: (doc_id: string, excerpt: string | undefined) => Promise<any>;
  downloadUserGuide: () => Promise<void>;
  downloadFAQ: () => Promise<void>;
}

const MainContext = createContext<MainContextType | undefined>(undefined);

interface MainProviderProps {
  children: ReactNode;
}

export const MainProvider: React.FC<MainProviderProps> = (props) => {
  const { toastedFetch } = useToast();

  const teamsContext = useContext(TeamsContext);
  const pathParts = window.location.pathname.split("/");
  const teamId = pathParts[1];
  const projectId = pathParts[2];
  const tool = pathParts[3];

  const backendUrl = process.env.REACT_APP_API_URL ?? "";
  const csrfToken = getCookie("csrftoken");
  const [notifications, setNotifications] = useState<Array<Notification_>>([]);
  const [teams, setTeams] = useState<Array<Team>>([]);
  const [teamSelected, setTeamSelected] = useState<Team | undefined>(undefined);
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [projectSelected, setProjectSelected] = useState<Project | undefined>(
    undefined
  );
  const [menuItemSelected, setMenuItemSelected] = useState<
    "TOOLS" | "RESULTS" | "SEARCH"
  >(
    tool
      ? (tool.toLocaleUpperCase() as "TOOLS" | "RESULTS" | "SEARCH")
      : "TOOLS"
  );
  const [metadataContractTypes, setMetadataContractTypes] = useState<
    Array<MetadataContractType>
  >([]);
  const [obligationContractTypes, setObligationContractTypes] = useState<
    Array<ObligationContractType>
  >([]);
  const [riskContractTypes, setRiskContractTypes] = useState<
    Array<RiskContractType>
  >([]);
  const [comparisonRegulationTypes, setComparisonRegulationTypes] = useState<
    Array<ComparisonRegulation>
  >([]);
  const [comparisonChecklistTypes, setComparisonChecklistTypes] = useState<
    Array<ComparisonChecklist>
  >([]);
  const [draftingTemplateTypes, setDraftingTemplateTypes] = useState<
    Array<DraftingTemplateType>
  >([]);
  const [summarizationDocumentTypes, setSummarizationDocumentTypes] = useState<
    Array<SummarizationContractType>
  >([]);
  const [summarizationTones, setSummarizationTones] = useState<
    Array<SummarizationToneType>
  >([]);
  const [searchMenuItemsSelected, setSearchMenuItemsSelected] = useState<
    "search" | "chat"
  >("chat");
  const [searchProjectSelected, setSearchProjectSelected] = useState<
    string | number
  >("all");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [searchPage, setSearchPage] = useState<number>(0);
  const [chatMessages, setChatMessages] = useState<any>([]);
  const [searchPagesNum, setSearchPagesNum] = useState<number>(0);
  const [isTextViewVisible, setIsTextViewVisible] = useState<boolean>(false);
  const [textViewContent, setTextViewContent] = useState<TextViewProps>({});

  const toolsContext = useContext(ToolsContext);

  useEffect(() => {
    const intervalId = setInterval(fetchNotifications, 5000);
    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   if (teams.length === 0) {
  //     // setTeamSelected(undefined);
  //   } else{
  //    setTeamSelected(teamId?
  //     teams?.filter(t=>t.id===parseInt(teamId))[0]
  //     :
  //     teams[0]);
  //   //  setProjectSelected(projectId?projects?.filter(p=>p.id===parseInt(projectId))[0]:undefined);

  //   }

  // }, [teams]);

  // useEffect(() => {
  //   if (projects.length === 0) {
  //     // setProjectSelected(undefined);

  //   } else{

  //   setProjectSelected(projectId?projects?.filter(p=>p.id===parseInt(projectId))[0]:projects[0]);
  //   }
  // }, [projects]);

  const searchUsers = async (search: string): Promise<UserValue[]> => {
    if (!search) return [];
    return fetch(`${backendUrl}/api/users/7/search/${search}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((body) =>
        body.map(
          (userProfile: {
            id: number | string;
            user: {
              username: string;
              first_name: string;
              last_name: string;
              email: string;
            };
            profile_picture: string;
          }) => ({
            label: `${
              userProfile.user.last_name
                ? userProfile.user.last_name
                : "Unknown"
            }, ${
              userProfile.user.first_name
                ? userProfile.user.first_name
                : "Unknown"
            } (${
              userProfile.user.username ? userProfile.user.username : "Unknown"
            })`,
            value: userProfile.id,
          })
        )
      );

    // const users = body.map((userProfile: {
    //   id: number | string;
    //   user: {
    //     username: string;
    //     first_name: string;
    //     last_name: string;
    //     email: string;
    //   },
    //   profile_picture: string;
    // }) => ({
    //   id: userProfile.id,
    //   email: userProfile.user.email ? userProfile.user.email : 'Unknown',
    //   firstname: userProfile.user.first_name ? userProfile.user.first_name : 'Unknown',
    //   lastname: userProfile.user.last_name ? userProfile.user.last_name : 'Unknown',
    //   fullname: `${userProfile.user.last_name ? userProfile.user.last_name : 'Unknown'}, ${userProfile.user.first_name ? userProfile.user.first_name : 'Unknown'}`,
    //   username: userProfile.user.username ? userProfile.user.username : 'Unknown',
    //   profilePicture: userProfile.profile_picture,
    // }));
  };

  const acceptTeamInvitation = async (notificationId: string | number) => {
    await fetch(`${backendUrl}/api/accept_invitation/${notificationId}`, {
      method: "POST",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchTeams();
    await fetchNotifications();
  };

  const declineTeamInvitation = async (notificationId: string | number) => {
    await fetch(`${backendUrl}/api/cancel_invitation/${notificationId}`, {
      method: "PATCH",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchNotifications();
  };

  const fetchNotifications = async () => {
    const response = await fetch(`${backendUrl}/api/notifications`, {
      method: "GET",
      credentials: "include",
    });
    const body = await response.json();

    body.sort((a: Notification_, b: Notification_) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      return dateB.getTime() - dateA.getTime();
    });

    setNotifications(body);
  };

  const updateNotificationsStatus = async (
    ids: Array<string | number>,
    status: boolean
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify(ids.map((id) => ({ id, active: status })));
    await fetch(`${backendUrl}/api/update_notifications_status/`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    fetchNotifications();
  };

  const readAllNotifications = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    await fetch(`${backendUrl}/api/read_all_notifications/`, {
      method: "PATCH",
      headers,
      credentials: "include",
    });

    fetchNotifications();
  };

  const deleteNotification = async (notificationId: string | number) => {
    await fetch(`${backendUrl}/api/delete_notification/${notificationId}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchNotifications();
  };

  const deleteAllNotifications = async () => {
    await fetch(`${backendUrl}/api/clear_notifications/`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchNotifications();
  };

  const fetchTeams = async () => {
    const response = await fetch(`${backendUrl}/api/teams`, {
      method: "GET",
      credentials: "include",
    });
    const body = await response.json();
    if (body.length === 0) return;
    if (
      teamId &&
      body.filter((t: Team) => t.id === parseInt(teamId)).length > 0
    ) {
      const filteredTeam = body.filter((t: Team) => t.id === parseInt(teamId));
      setTeamSelected({
        id: filteredTeam[0].id,
        name: filteredTeam[0].name,
        user_role: filteredTeam[0].user_role,
        tools: filteredTeam[0].tools.map(
          (tool: { id: string | number; name: string }) => tool.name
        ),
      });
    } else {
      setTeamSelected({
        id: body[0].id,
        name: body[0].name,
        user_role: body[0].user_role,
        tools: body[0].tools.map(
          (tool: { id: string | number; name: string }) => tool.name
        ),
      });
    }

    setTeams(
      body.map(
        (team: {
          id: string | number;
          name: string;
          user_role: string;
          tools: Array<{ id: string | number; name: string }>;
        }) => ({
          id: team.id,
          name: team.name,
          user_role: team.user_role,
          tools: team.tools.map((tool) => tool.name),
        })
      )
    );
  };

  const fetchMetadataContractTypes = async () => {
    const response = await fetch(
      `${backendUrl}/api/metadata_contract_types/${teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: MetadataContractType[] = await response.json();

    setMetadataContractTypes(body);
  };

  const deleteMetadataContractType = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_metadata_contract_type/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchMetadataContractTypes();
  };

  const editMetadataContractType = async (
    id: string | number,
    data: { name: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(`${backendUrl}/api/edit_metadata_contract_type/${id}`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    await fetchMetadataContractTypes();
  };

  const createMetadataContractType = async (data: { name: string }) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(
      `${backendUrl}/api/create_metadata_contract_type/${teamSelected?.id}`,
      { method: "POST", body, headers, credentials: "include" }
    );

    await fetchMetadataContractTypes();
  };

  const deleteMetadataType = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_metadata_type/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchMetadataContractTypes();
  };

  const editMetadataType = async (
    id: string | number,
    data: {
      name: string;
      description: string;
      type: string;
      output_format: string;
    }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({
      name: data.name,
      description: data.description,
      type: data.type,
      output_format: data.output_format,
    });
    await fetch(`${backendUrl}/api/edit_metadata_type/${id}`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    await fetchMetadataContractTypes();
  };

  const createMetadataType = async (
    metadataContractTypeId: string | number,
    data: {
      name: string;
      description: string;
      type: string;
      output_format: string;
    }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({
      name: data.name,
      description: data.description,
      type: data.type,
      output_format: data.output_format,
    });
    await fetch(
      `${backendUrl}/api/create_metadata_type/${metadataContractTypeId}`,
      { method: "POST", body, headers, credentials: "include" }
    );

    await fetchMetadataContractTypes();
  };

  const deleteObligationContractType = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_obligation_contract_type/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchObligationContractTypes();
  };

  const editObligationContractType = async (
    id: string | number,
    data: { name: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(`${backendUrl}/api/edit_obligation_contract_type/${id}`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    await fetchObligationContractTypes();
  };

  const createObligationContractType = async (data: { name: string }) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(
      `${backendUrl}/api/create_obligation_contract_type/${teamSelected?.id}`,
      { method: "POST", body, headers, credentials: "include" }
    );

    await fetchObligationContractTypes();
  };

  const deleteObligationType = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_obligation_type/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchObligationContractTypes();
  };

  const createObligationType = async (
    obligationContractTypeId: string | number,
    data: { name: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(
      `${backendUrl}/api/create_obligation_type/${obligationContractTypeId}`,
      { method: "POST", body, headers, credentials: "include" }
    );

    await fetchObligationContractTypes();
  };

  const editObligationType = async (
    id: string | number,
    data: { name: string; description: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({
      name: data.name,
      description: data.description,
    });
    await fetch(`${backendUrl}/api/edit_obligation_type/${id}`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    await fetchObligationContractTypes();
  };

  const deleteRiskContractType = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_risk_contract_type/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchRiskContractTypes();
  };

  const createRiskContractType = async (data: { name: string }) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(
      `${backendUrl}/api/create_risk_contract_type/${teamSelected?.id}`,
      { method: "POST", body, headers, credentials: "include" }
    );

    await fetchRiskContractTypes();
  };

  const editRiskContractType = async (
    id: string | number,
    data: { name: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(`${backendUrl}/api/edit_risk_contract_type/${id}`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    await fetchRiskContractTypes();
  };

  const deleteRiskType = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_risk_type/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchRiskContractTypes();
  };

  const createRiskType = async (
    riskContractTypeId: string | number,
    data: { name: string; description: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({
      name: data.name,
      description: data.description,
    });
    await fetch(`${backendUrl}/api/create_risk_type/${riskContractTypeId}`, {
      method: "POST",
      body,
      headers,
      credentials: "include",
    });

    await fetchRiskContractTypes();
  };

  const editRiskType = async (
    id: string | number,
    data: { name: string; description: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({
      name: data.name,
      description: data.description,
    });
    await fetch(`${backendUrl}/api/edit_risk_type/${id}`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    await fetchRiskContractTypes();
  };

  const deleteComparisonRegulationType = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_comparison_regulation_type/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchComparisonRegulations();
  };

  const editComparisonRegulationType = async (
    id: string | number,
    data: { name: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(`${backendUrl}/api/edit_comparison_regulation_type/${id}`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    await fetchComparisonRegulations();
  };

  const createComparisonRegulationType = async (data: { name: string }) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(
      `${backendUrl}/api/create_comparison_regulation_type/${teamSelected?.id}`,
      { method: "POST", body, headers, credentials: "include" }
    );

    await fetchComparisonRegulations();
  };

  const deleteComparisonChecklistType = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_comparison_checklist_type/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchComparisonChecklists();
  };

  const editComparisonChecklistType = async (
    id: string | number,
    data: { name: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(`${backendUrl}/api/edit_comparison_checklist_type/${id}`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    await fetchComparisonChecklists();
  };

  const createComparisonChecklistType = async (data: { name: string }) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({ name: data.name });
    await fetch(
      `${backendUrl}/api/create_comparison_checklist_type/${teamSelected?.id}`,
      { method: "POST", body, headers, credentials: "include" }
    );

    await fetchComparisonChecklists();
  };

  const fetchObligationContractTypes = async () => {
    const response = await fetch(
      `${backendUrl}/api/obligation_contract_types/${teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: ObligationContractType[] = await response.json();

    setObligationContractTypes(body);
  };

  const fetchRiskContractTypes = async () => {
    const response = await fetch(
      `${backendUrl}/api/risk_contract_types/${teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: RiskContractType[] = await response.json();

    setRiskContractTypes(body);
  };

  const fetchSummarizationContractTypes = async () => {
    const response = await fetch(
      `${backendUrl}/api/summarization_contract_types/${teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: SummarizationContractType[] = await response.json();

    setSummarizationDocumentTypes(body);
  };
  const fetchSummarizationToneTypes = async () => {
    const response = await fetch(
      `${backendUrl}/api/summarization_tone_types/${teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body: SummarizationToneType[] = await response.json();

    setSummarizationTones(body);
  };

  const fetchComparisonRegulations = async () => {
    const response = await fetch(
      `${backendUrl}/api/comparison_regulation_types/${teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body = await response.json();

    setComparisonRegulationTypes(body);
  };

  const fetchComparisonChecklists = async () => {
    const response = await fetch(
      `${backendUrl}/api/comparison_checklist_types/${teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body = await response.json();

    setComparisonChecklistTypes(body);
  };

  const fetchDraftingTemplateType = async () => {
    const response = await fetch(
      `${backendUrl}/api/get_drafting_template/${teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body = await response.json();

    setDraftingTemplateTypes(body);
  };

  const deleteDraftingTemplateType = async (id: string | number) => {
    await fetch(`${backendUrl}/api/delete_drafting_template/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchDraftingTemplateType();
  };

  const editDraftingTemplateType = async (
    id: string | number,
    data: { name: string; description: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify({
      name: data.name,
      description: data.description,
    });
    await fetch(`${backendUrl}/api/edit_drafting_template/${id}`, {
      method: "PATCH",
      body,
      headers,
      credentials: "include",
    });

    await fetchDraftingTemplateType();
  };

  const createDraftingTemplateType = async (data: {
    name: string;
    description: string;
    file: any;
    baseline: string;
  }) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("file", data.file);
    formData.append("baseline", data.baseline ?? "weak");

    fetch(`${backendUrl}/api/create_drafting_template/${teamSelected?.id}`, {
      method: "POST",
      body: formData,
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    fetchNotifications();
    fetchDraftingTemplateType();
  };

  const fetchProjects = async () => {
    const response = await fetch(
      `${backendUrl}/api/projects/${teamSelected?.id}`,
      { method: "GET", credentials: "include" }
    );
    const body = await response.json();

    body.sort((a: Project, b: Project) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      return dateB.getTime() - dateA.getTime();
    });

    setProjects(body);
    if (projectId) {
      setProjectSelected(
        body.find((p: Project) => p.id === parseInt(projectId))!
      );
    } else {
      setProjectSelected(body[0]);
    }
  };

  const createProject = async (name: string, description: string) => {
    const formData = new FormData();
    formData.append("team", teamSelected!.id.toString());
    formData.append("name", name);
    formData.append("description", description);

    const response = await toastedFetch(`${backendUrl}/api/create_project/`, {
      method: "POST",
      body: formData,
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    const responseData: { name: string } = await response.json();
    await fetchProjects();
    return responseData;
  };

  const editProject = async (
    id: string | number,
    data: { name: string; description: string }
  ) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-CSRFToken", csrfToken ?? "");
    const body = JSON.stringify(data);

    const response = await toastedFetch(
      `${backendUrl}/api/edit_project/${id}`,
      {
        method: "PATCH",
        body,
        headers,
        credentials: "include",
      }
    );

    const responseData: { name: string } = await response.json();
    await fetchProjects();
    return responseData;
  };

  const deleteProject = async (id: number | string) => {
    await toastedFetch(`${backendUrl}/api/delete_project/${id}`, {
      method: "DELETE",
      headers: { "X-CSRFToken": csrfToken ?? "" },
      credentials: "include",
    });

    await fetchProjects();
  };

  // CHAT
  const handleChatRequest = async (messages: Array<any>, project: any,   cb?: ()=>void) => {
    try {
      const response = await fetch(`${backendUrl}/api/search/chat/`, {
        method: "POST",
        body: JSON.stringify({ messages, project: project.id }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        credentials: "include",
 
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch chat response");
      }
  
      // Clone the response so we can return one version and process the other
      const clonedResponse = response.clone();
  
      const reader = clonedResponse.body?.getReader();
      const decoder = new TextDecoder();
      let result = "";
  
      if (reader) {
        const stream = new ReadableStream({
          async start(controller) {
            while (true) {
              const { done, value } = await reader.read();
              if (done) {
                console.log('Stream ended');
                cb?.();
                controller.close();
                break;
              }
              // Process the received chunk
              result += decoder.decode(value, { stream: true });
              controller.enqueue(value);
            }
          },
        });
  
        // You can use `result` after the stream ends, for example:
        // console.log('Complete chat message:', result);
      }
  
      return response; // Return the original response, since we've processed the clone
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Chat request was aborted');
        // You might want to handle this case specifically
      }
      throw error; // Re-throw the error to be handled by the caller
    }
  };
  const handleSearchRequest = async (
    query: string,
    project: Number | String | undefined,
    page: Number
  ) => {
    const response = await fetch(
      `${backendUrl}/api/search/search?q=${query}&project=${project}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch search response");
    }

    return response;
  };

  const get_doc_text = async (doc_id: string, excerpt: string | undefined) => {
    let url = `${backendUrl}/api/search/get_doc_text?doc_id=${doc_id}`;
    if (excerpt) {
      url += `&excerpt=${excerpt.replaceAll("\n", " ")}`;
    }
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const body = await response.json();
    return body;
  };

  // EXPORTERS
  // CSV
  // const exportMetadataToCSV = async (resultId: string | number) => {
  //   await downloadFile(await fetch(`${backendUrl}/api/export_metadata_CSV/${resultId}`, { method: "GET", credentials: "include" }));
  // };
  // const batchExportMetadataToCSV = async (
  //   resultIds: Array<string | number | bigint>
  // ) => {
  //   await downloadFile(await fetch(`${backendUrl}/api/batch_export_metadata_CSV/`, { method: "POST", credentials: "include", headers: { "X-CSRFToken": csrfToken ?? "", "Content-Type": "application/json", }, body: JSON.stringify({ ids: resultIds }) }));
  // };
  // const exportObligationToCSV = async (resultId: string | number) => {
  //   await downloadFile(await fetch(`${backendUrl}/api/export_obligation_CSV/${resultId}`, { method: "GET", credentials: "include" }));
  // };
  // const batchExportObligationToCSV = async (
  //   resultIds: Array<string | number | bigint>
  // ) => {
  //   await downloadFile(await fetch(`${backendUrl}/api/batch_export_obligation_CSV/`, { method: "POST", credentials: "include", headers: { "X-CSRFToken": csrfToken ?? "", "Content-Type": "application/json", }, body: JSON.stringify({ ids: resultIds }) }));
  // };
  // const exportRiskToCSV = async (resultId: string | number) => {
  //   await downloadFile(await fetch(`${backendUrl}/api/export_risk_CSV/${resultId}`, { method: "GET", credentials: "include" }));
  // };
  // const batchExportRiskToCSV = async (resultIds: Array<string | number | bigint>) => {
  //   await downloadFile(await fetch(`${backendUrl}/api/batch_export_risk_CSV/`, { method: "POST", credentials: "include", headers: { "X-CSRFToken": csrfToken ?? "", "Content-Type": "application/json", }, body: JSON.stringify({ ids: resultIds }) }));
  // };
  // const exportComparisonToCSV = async (resultId: string | number) => {
  //   await downloadFile(await fetch(`${backendUrl}/api/export_comparison_CSV/${resultId}`, { method: "GET", credentials: "include" }));
  // };
  // const batchExportComparisonToCSV = async (resultIds: Array<string | number | bigint>) => {
  //   await downloadFile(await fetch(`${backendUrl}/api/batch_export_comparison_CSV/`, { method: "POST", credentials: "include", headers: { "X-CSRFToken": csrfToken ?? "", "Content-Type": "application/json", }, body: JSON.stringify({ ids: resultIds }) }));
  // };

  const downloadUserGuide = async () => {
    await downloadFile(
      await fetch(`${backendUrl}/api/download_user_guide/`, {
        method: "GET",
        credentials: "include",
      })
    );
  };

  const downloadFAQ = async () => {
    await downloadFile(
      await fetch(`${backendUrl}/api/download_faq/`, {
        method: "GET",
        credentials: "include",
      })
    );
  };

  return (
    <MainContext.Provider
      value={{
        searchUsers,
        acceptTeamInvitation,
        declineTeamInvitation,
        menuItemSelected,
        setMenuItemSelected,
        notifications,
        updateNotificationsStatus,
        readAllNotifications,
        deleteNotification,
        deleteAllNotifications,
        teams,
        setTeams,
        teamSelected,
        setTeamSelected,
        metadataContractTypes,
        obligationContractTypes,
        riskContractTypes,
        comparisonRegulationTypes,
        comparisonChecklistTypes,
        draftingTemplateTypes,
        summarizationDocumentTypes,
        summarizationTones,
        projects,
        projectSelected,
        setProjectSelected,
        fetchNotifications,
        fetchTeams,
        fetchMetadataContractTypes,
        fetchObligationContractTypes,
        fetchRiskContractTypes,
        fetchComparisonRegulations,
        fetchComparisonChecklists,
        fetchSummarizationContractTypes,
        fetchSummarizationToneTypes,
        deleteMetadataContractType,
        editMetadataContractType,
        createMetadataContractType,
        deleteMetadataType,
        editMetadataType,
        createMetadataType,
        deleteObligationContractType,
        createObligationContractType,
        editObligationContractType,
        deleteObligationType,
        createObligationType,
        editObligationType,
        deleteRiskContractType,
        createRiskContractType,
        editRiskContractType,
        deleteRiskType,
        createRiskType,
        editRiskType,
        deleteComparisonRegulationType,
        createComparisonRegulationType,
        editComparisonRegulationType,
        deleteComparisonChecklistType,
        createComparisonChecklistType,
        editComparisonChecklistType,
        fetchDraftingTemplateType,
        deleteDraftingTemplateType,
        editDraftingTemplateType,
        createDraftingTemplateType,
        fetchProjects,
        createProject,
        editProject,
        deleteProject,
        // exportMetadataToCSV,
        // batchExportMetadataToCSV,
        // exportObligationToCSV,
        // batchExportObligationToCSV,
        // exportRiskToCSV,
        // batchExportRiskToCSV,
        // exportComparisonToCSV,
        // batchExportComparisonToCSV,
        searchMenuItemsSelected,
        setSearchMenuItemsSelected,
        handleChatRequest,
        searchProjectSelected,
        setSearchProjectSelected,
        searchQuery,
        setSearchQuery,
        handleSearchRequest,
        searchResults,
        setSearchResults,
        searchPage,
        setSearchPage,
        chatMessages,
        setChatMessages,
        searchPagesNum,
        setSearchPagesNum,
        isTextViewVisible,
        setIsTextViewVisible,
        textViewContent,
        setTextViewContent,
        get_doc_text,
        downloadUserGuide,
        downloadFAQ,
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContext;
export type { MainContextType };
