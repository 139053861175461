export enum ToolTypes {
  MetadataExtractor = "metadata",
  ObligationsExtractor = "obligation",
  RisksIdentifier = "risk",
  DocumentComparison = "comparison",
  DocumentDrafting = "drafting",
  TimelineAnalysis = "timeline_analysis",
  Translator = "translation",
  InvoiceReview = "invoice",
}
