import React, { useState, useContext } from "react";
import { Button, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ResultTypes } from "../../enums/resultTypes.enum";
import batchExportXLSXFunctions from "../../helpers/batchExportXLSXFunctions";

import styles from "./BatchActions.module.css";
import DeleteResultsModal from "../DeleteResultsModal/DeleteResultsModal";
import * as ResultsService from "../../services/results.service";
import getCookie from "../../../../helpers/getCookie";

import ResultsContext from "../../../../contexts/ResultsContext";

interface BatchActionsProps {
  toolSelected: ResultTypes | undefined;
  selectedRows: React.Key[];
}

const BatchActions: React.FC<BatchActionsProps> = ({
  toolSelected,
  selectedRows,
}) => {
  const resultsContext = useContext(ResultsContext);

  const csrfToken = getCookie("csrftoken") ?? "";

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await ResultsService.deleteResults(csrfToken, selectedRows);
    setIsDeleting(false);
    resultsContext?.setFetchingToggle((prev) => !prev);
    setIsOpenDeleteModal(false);
  };

  const handleCancel = () => {
    setIsOpenDeleteModal(false);
  };

  if (!toolSelected) return <></>;

  return (
    <>
      <div className={styles.batch_actions_menu}>
        {toolSelected !== ResultTypes.Drafting &&
          toolSelected !== ResultTypes.Translation &&
          toolSelected !== ResultTypes.Timeline && (
            <>
              <Dropdown
                disabled={selectedRows.length === 0}
                overlayStyle={{
                  border: "2px solid #ffffff40",
                  borderRadius: 8,
                }}
                menu={{
                  items: [
                    {
                      label: "XLSX",
                      key: 0,
                      onClick: () =>
                        batchExportXLSXFunctions[toolSelected](
                          csrfToken,
                          selectedRows
                        ),
                    },
                  ],
                }}
              >
                <a
                  href="_"
                  className={`${styles.batch_button} ${
                    selectedRows.length !== 0
                      ? styles.batch_button_enabled
                      : styles.batch_button_disabled
                  }`}
                  onClick={(e) => e.preventDefault()}
                >
                  <Space>
                    Batch export
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>

              <div className={styles.vertical_divider} />
            </>
          )}

        <Button
          onClick={() => setIsOpenDeleteModal(true)}
          disabled={selectedRows.length === 0}
          danger
        >
          Delete
        </Button>
      </div>

      <DeleteResultsModal
        isOpen={isOpenDeleteModal}
        isDeleting={isDeleting}
        onDeleteResults={handleDelete}
        onCancelDelete={handleCancel}
      />
    </>
  );
};

export default BatchActions;
